<template>
    <div class="container">


        <h2 class="head2 mb-5">{{Lang.get('link2')}}</h2>
        <div class="text-red bold Truculenta text-32" style="max-width:550px;margin:auto;" v-html="Lang.get('spot2')"></div>
        <div>
            <div>
                <img src="../assets/ceo.png" class="img-fluid" />
                <div class="Cloud-Text2">
                    <div v-html="Lang.get('text2')">

                    </div>
                </div>
            </div>

        </div>
        <div v-if="Lang.SelectedLanguage=='tr'">
            <div style="max-width:550px;margin:auto;">
                <center>

                    <h4>Değerli Paydaşlarımız,</h4>
                    <p>
                        2020, pandeminin beraberinde getirdiği zorlukları aşmak için, tüm insanlığın ortak dayanışma gösterdiği önemli bir yıl olarak hatırlanacak. Böylesi bir ortamda Ülker Bisküvi olarak bizler de; üretimden başlayarak tüketiciye ulaşana kadar tüm süreçlerde üzerimize düşen sorumlulukları eksiksiz yerine getirmeyi başardık. Üretimimizi aralıksız devam ettirerek, ekonominin çarklarının dönmesine destek olduk, Bu dönemin stratejik sektörü olarak, ülkemize hizmet vermeye aralıksız devam ettik.
                    </p><p>
                        Pandemi karşısında planlarımızı, gelişmeleri yakından takip ederek, dinamik bir şekilde uygularken; esnek ve çevik iş modelimiz sayesinde iş süreçlerimizde yeni şartlara uygun değişiklikler yaptık. Üretimimizle ihracatımızla istihdamımızla ülkemize katkı sağlamayı sürdürdük.
                    </p><p>
                        Pandemi sürecinin başından bu yana önceliğimiz insan sağlığı oldu. Çalışanlarımızın ve ailelerinin sağlığı için fabrikalarımız dâhil tüm çalışma alanlarımızda Sağlık Bakanlığı’nın 14 kuralını uyguladık. Ayrıca eş zamanlı “14+14” olarak adlandırdığımız ek tedbirleri de devreye aldık. Özellikle pandemi döneminde tüketicilerin talebini karşılama sorumluluğuyla tüm iş birimlerimizle aralıksız üretmeye ve güçlü dağıtım ağımızla, ürünlerimizi Türkiye’nin dört bir yanındaki 190 bin satış noktasına dolayısıyla tüketicilerimize ulaştırmaya devam ettik.
                    </p><p>
                        Ciromuzu %20,5 artırdık
                        Ülker Bisküvi olarak 2020 yılında faaliyet gösterdiğimiz tüm coğrafyalarda sürdürülebilir büyümemizi devam ettirirken, kârlılığımızı da önemli ölçüde artırdık. Konsolide ciromuzu %20,5 büyüterek 9,4 milyar TL’ye, Faiz Amortisman ve Vergi Öncesi Kârımızı %24,4 artırarak %17,2 FAVÖK marjına ulaştırdık. Türkiye’de toplam atıştırmalık pazarında tartışmasız liderliğimizi devam ettirdik. Ülkemizi üretim ve ihracat üssü haline getirirken, satış ve kârlılığı artırdık. Türkiye operasyonlarımızı %18,6 ihracat ve yurt dışı operasyonlarımızı %23,4 büyüttük.
                    </p><p>
                        İki fabrikamızla faaliyet gösterdiğimiz Suudi Arabistan pazarında bisküvi kategorisinde pazar liderliğimizi sağlamlaştırırken, Orta Doğu, Kuzey Afrika ve Orta Asya pazarlarında pozisyonumuzu güçlendirdik. Orta Asya’ya açılan kapımız olan Kazakistan’da da satışlarımız istikrarlı büyümesini devam ettirdi. Bunların haricinde çok güçlü bilanço ve net borç oranıyla 2020 yılını başarıyla tamamladık.
                    </p><p>
                        Dünyanın dört bir yanında yer alan 100’ün üzerinde ülkeye ihracatımızla 9 binden fazla çalışanımızla Türkiye’de 6 ve yurt dışında 4 olmak üzere toplam 10 fabrikamızdaki üretimimizle ülke ekonomisine katkı sağlıyoruz. Türkiye’deki verimlilik çalışmalarımız maliyet avantajı sağlarken yeni ürünlerimizle lider olduğumuz kategorilerde iddiamızı daha da güçlendiriyoruz.
                    </p><p>
                        2020 yılında 455 milyon ABD doları tutarında kredi anlaşması ve 650 milyon ABD dolarlık tahvil ihracı gerçekleştirdik. Ülker Bisküvi, yılın ilk yarısında toplam yedi uluslararası bankanın ve Avrupa İmar ve Kalkınma Bankası’nın (EBRD) katılımıyla toplam 455 milyon ABD doları tutarında kredi anlaşması imzaladı. Yılın ikinci yarısında ise, 1,8 milyar ABD doları taleple 28 ülkeden 130’dan fazla uluslararası yatırımcının iştirak etmesiyle gerçekleştirdiğimiz ilk tahvil ihracıyla Şirketimize uluslararası yatırımcılar tarafından uzun vadeli finansman sağlanması dünyadaki zorlu ekonomik şartlara rağmen hem ülkemize hem de Şirketimize olan güvenin bir göstergesi niteliğindeydi. Ülker Bisküvi sağlıklı bir finansman modeline dayanan, yatırım odaklı bir anlayışla büyümesini sürdürecek.
                    </p><p>
                        İnovasyon büyümemizde itici güç oldu
                        Ar-Ge faaliyetlerimiz sonucunda tüketiciyi mutlu edecek yenilikçi ürünlerle tüketicilerimize ulaşıyoruz. Talep ve ihtiyaçlar doğrultusunda yeni ürün geliştiriyor ve mevcut ürünlerde çeşidi artırma ve geliştirme projeleri yapıyoruz. 2020’de fabrikalarımızda hat yatırımları yaparak, başta Ülker Çikolata, Dido, Ülker Çikolatalı Gofret, Hanımeller, Albeni, O’lala, Biskrem, Ülker Kremalı, Çizi markaları altında olmak üzere, birçok inovatif ürünün lansmanını ve relansmanlarını gerçekleştirdik.
                        2019-2020 yılında çikolata, bisküvi ve kek kategorilerinde toplamda 70’in üzerinde yeni ürünü piyasaya sunduk. Son üç yılda yeni ürünlerimizin satış içindeki payını da ciddi oranda artırdık, inovasyonlarımız Şirketimizin büyümesinde itici güçlerden biri haline geldi.
                    </p><p>
                        Dijitalleşme hep gündemimizdeydi
                        Dijitalleşme uzun zamandır gündemimizde olan ve yatırım yaptığımız bir alandı. Bu öngörümüz sayesinde yeni şartlara hızlı uyum sağladık. Dönüşen mesai, üretim ve tedarik yönetiminde hayata geçirdiğimiz projelerle verimliliği artırdık. “Merchandising” projemizle tanzim teşhir faaliyetlerimizi, “Goya Asistanı” projemizle ise saha ziyaretlerimizi dijital platforma taşıdık. Sahada kalemle not alma, problemleri konunun ilgilisine e-posta ile iletme dönemi sona erdi. “Görüntü İşleme” teknolojisini iş yapma süreçlerimize entegre ettik. “Robotik Süreç Otomasyonuyla” manuel ve tekrarlayan, katma değersiz süreçlerde otomasyona geçildi. “IOT Anlık Üretim Takip Projesiyle” üretim hatlarındaki sensörlerle hatların anlık üretim performansının izlenmesi sağlandı. İnsan kaynakları alanında işe alım ve oryantasyon süreçlerini dijitalleştirerek süreçleri sadeleştirip hızlandırdık. Üretimi kesintiye uğratmadan çalışan sağlığına en üst düzeyde önem verdiğimiz bu dönemde dijital dönüşüm yöntemlerimiz, ciro ve ihracat rakamlarımıza da olumlu yansıdı.
                    </p><p>
                        Paydaşlarla birlikte büyüme anlayışı
                        Sürdürülebilirlik, dünyamızın ve insanlığın geleceği açısından kritik bir önem taşıyor. İçinde bulunduğumuz pandemi koşullarında, sürdürülebilirlik konusunda bugüne kadar attığımız adımların ne kadar değerli olduğu bir kez daha anlaşıldı. Kurulduğumuz günden bu yana “israfsız şirket” modeliyle çalışırken; toplumla, paydaşlarımızla birlikte büyümeyi ve güçlenmeyi merkeze alan bir anlayışla faaliyet gösteriyoruz. Şirketlerimizde su ve enerji tasarrufundan, insan kaynağına ve üretim kalitesine kadar pek çok konuda projeler üretiyoruz. Bu çabalarımızı belirgin bir strateji etrafında izlenebilir ve denetlenebilir kılmak için Birleşmiş Milletler’in 2030 Sürdürülebilir Kalkınma Hedeflerini temel alıyoruz. İş yapış şeklimizin merkezinde bulanan sürdürülebilirlik çalışmalarımız, topraktan başlayarak; üreticilerimizi, tedarikçilerimizi, paydaşlarımızı, iş ortaklarımızı ve tüketiciyi de içine alarak şekilleniyor. Ülker Bisküvi’de bu döngüyü korumanın ülkemizin, dolayısıyla dünyamızın geleceğine yapabileceğimiz en önemli katkı olduğuna inanıyoruz.
                    </p><p>
                        Sadece üretim ve tedarik aşamasında değil; faaliyetlerimizin her adımında kaynakları israf etmeden sürdürülebilir bir büyüme hedefliyoruz. Bu nedenle tedarik sürecinde yerel tedarikçilere öncelik veriyoruz. Yerel üreticilerimizi destekleyerek, sürdürülebilir tarım uygulamaları geliştiriyoruz. Gıdada arz güvenliği konusunda atılan adımların ve yerel üreticiye verilen desteklerin, ülkemizin sürdürülebilir kalkınma hedefleri için kritik bir önem taşıdığını çok iyi biliyoruz. Bu nedenle “Gıda Arzı Güvenliği” alanına katkı sağlayan projelere yoğunlaşıyoruz.
                    </p><p>
                        Türkiye’de bir ilk: Yerli ve milli Aliağa Bisküvilik Buğdayı
                        Tarım ve Orman Bakanlığı’na bağlı tarım araştırma enstitüleriyle buğday ve yulaf özelinde çalışmalar yaparak, ülke tarımının geleceğine katkı sağlamaya çalışıyoruz. Bahri Dağdaş Uluslararası Tarımsal Araştırma Enstitüsü’yle ortaklaşa, uzun yıllar süren çalışmalarımız sonunda ortaya çıkan Aliağa Bisküvilik Buğdayı’yla tarıma yerli, milli, kuraklık, hastalık ve iklim değişikliğine dayanıklı, yüksek verimli, kaliteli ve Türkiye’de ilk olan bir buğday türü kazandırdık. İlk hasadını 2020’nin Temmuz ayında gerçekleştirdiğimiz buğdayın, üretimimizin kalbinde yer alan çiftçilerimizden takdir görmesi bizi çok mutlu etti. Bu projemizle Tarım ve Orman Bakanımız Sayın Bekir Pakdemirli’nin elinden “Tarımı Geleceğe Taşıyanlar” ödülünü almanın mutluluğunu da yaşadık.
                    </p><p>
                        Doğayı korumak adına en çok değer verdiğimiz konulardan biri de topraklarımızı fidanlarla buluşturarak, nefes alınabilir bir geleceğe katkıda bulunmak. Tarım ve Orman Bakanlığı’nın Geleceğe Nefes Kampanyası kapsamında Hatay, Karaman, Ankara’da 11 bin, TEMA Vakfı aracılığıyla da Elazığ ve Eskişehir’de 20 bin olmak üzere, toplamda 31 bin fidan diktik. Uzun vadeli hedeflerle sürdürülebilirlik konusunda çalışmalarımızı yürütürken, doğal kaynakların korunması, su tasarrufu, enerji verimliliği, atık yönetimi, karbon ayak izinin azaltılması ve gıda israfının önlenmesi konularında Güzel Ülkem Doğa Bize Emanet başlığıyla bir video serisi hazırladık. 2020 yılın son çeyreğinde sosyal medya kanallarımızdan paylaşmaya başladığımız videolarla sürdürülebilirlik konusunda farkındalığı artırmayı amaçlarken, Şirket olarak yaptıklarımızı, bireysel olarak neler yapılabileceğini anlatmak istedik.
                    </p><p>
                        Ulusal ve uluslararası ödüllerle başarımızı taçlandırdık
                        Nielsen ve Sustainable Brands İstanbul iş birliğiyle gerçekleştirilen Good Life/İyi Yaşam Markaları Araştırması 2020’ye göre, üst üste üçüncü kez “iyi yaşam markası” seçildik.
                    </p><p>
                        Dünyanın en prestijli iş ödülleri arasında gösterilen “Edie Sürdürülebilirlik Liderleri Ödülleri”nde Su Yönetimi, Atık ve Kaynak Yönetimi, Yılın Takımı, Çalışan Katılımı ve Kültürel Dönüşüm Liderliği” olmak üzere dört kategoride birden finale kalan tek Türk şirketi olmanın onurunu yaşadık.
                    </p><p>
                        “Boc International Brilliance Awards” global yarışma platformunda “Çalışan Bağlılığında Mükemmellik” ve “İç İletişimde Teknoloji Kullanımında Mükemmellik” kategorilerinde iki “Altın Ödül”ün sahibi olduk.
                    </p><p>
                        Platin Dergisi’nin 2020 yılında dijitalleşme temasıyla gerçekleştirilen Global 100 Ödülleri’nde, dijitalleşmede yaptığı yatırımlarla ön plana çıkan 10 şirketten biri ve gıda sektörünün birincisi olarak ödül almaktan büyük mutluluk duyduk.
                    </p><p>
                        Ülker Bisküvi’de, 2021 yılında da büyüme performansımızı sürdürerek; çalışanlarımız, tüketicilerimiz ve paydaşlarımıza odaklı; dijital dönüşümlere hız vererek yeni yatırımlar ve güçlü inovasyonlarla ülkemizin kalkınma hedeflerine destek olup, global rekabette güç kazanmak, bulunduğumuz pazarlarda güçlü konumumuzu koruyarak ve liderliklerimizi devam ettirmek için çalışacağız.
                    </p><p>
                        Ülkemiz için üretmeye, istihdama, ihracata, yatırıma devam ederken bize bu yolda destek veren tüm paydaşlarımıza en içten teşekkürlerimi sunuyorum.
                    </p>
                    <p>
                        Saygılarımla,
                    </p>


                    <strong>Mete Buyurgan</strong><br />
                    Yönetim Kurulu Başkanı
                    CEO
                </center>
            </div>
        </div>
        <div v-if="Lang.SelectedLanguage=='en'">
            <div style="max-width:550px;margin:auto;">
                <center>

                    <p>
                        Digitalization agenda at the forefront
                        Digitalization has long been part of our agenda. Thanks to this vision and our investments into digitalization, we swiftly adapted to the new conditions. We increased efficiency through initiatives on transforming labor, production and supply management. We extended merchandising activities and site visits to the digital environment through our “Merchandising” and “Goya Assistant” projects, respectively. The time of taking notes by hand at the site and e-mailing the related contact has ended. We integrated “Image Processing” technology into our business processes; we now use “Robotic Process Automation” to avoid manual and repetitive processes with no added value. Through the “IOT Instant Production Tracking Project,” we can instantly monitor the production performance of lines via sensors installed on the production lines. We moved human resources recruitment and orientation processes to digital formats and simplified them for swifter management. While paying the utmost attention to the health of our employees – without interrupting production – our digital transformation methods reflected very positively on revenue and exportation figures.
                    </p>
                    <p>
                        A philosophy of growing together with our stakeholders
                        Sustainability is of critical significance for the future of our world and humanity. The pandemic confirmed the value of steps taken so far in terms of sustainability. Adopting a “no-waste company” model since our establishment, we continue our operations with an approach that is focused on growing and strengthening together with society, our stakeholders. We develop various projects on water and energy preservation, human resources and production quality; these efforts are based on the United Nations 2030 Sustainable Development Goals in order to monitor and audit them in line with a certain strategy. We place sustainability efforts at the core of our way of business: these efforts are shaped from the ground up and include producers, suppliers, stakeholders, business partners and consumers. At Ülker Bisküvi, we believe that we make a greater contribution to the future of our country, as well as the world, when we protect and participate in this cycle.
                    </p>
                    <p>
                        Our goal is to achieve sustainable growth without wasting resources – not only during production and supply phases, but in every step of our activities. As such, we prioritize local suppliers during the supply process. By supporting local producers, we enable the development of sustainable agricultural practices. We are highly aware of the significance of steps taken regarding supply security in the food industry and the supports provided to local producers in terms of the sustainable development goals of our country. Thus, we focus on projects that contribute in the “Security of Food Supply” area.
                    </p>
                    <p>
                        Turkey’s first local and national biscuit wheat: Aliağa
                        We conduct research particularly on wheat and oat together with the agriculture research institutes of the Turkish Ministry of Agriculture and Forestry; and we strive to contribute to the future of agriculture in Turkey. We collaborated with Bahri Dağdaş International Agricultural Research Institute and, after long years of study, we developed local and national Aliağa Biscuit Wheat. This wheat is resistant to drought, diseases and climate change; it is high-yielding, high-quality, and a first of its kind in Turkey. The first harvest of the wheat took place in July 2020 and we were glad to see that the wheat was greatly appreciated by our farmers, the heart of our production. We were pleased and proud that the project was granted the “Carrying Agriculture to the Future” award by Bekir Pakdemirli, the Turkish Minister of Agriculture and Forestry.
                    </p>
                    <p>
                        In terms of protecting the environment, we believe that a significant mission is to populate our lands with saplings – a contribution to a habitable future. We planted a total of 31 thousand saplings:
                        11 thousand saplings were planted in Hatay, Karaman and Ankara for the “A Breath for the Future” campaign of the Ministry of Agriculture and Forestry; and 20 thousand saplings were planted in Elazığ and Eskişehir through the TEMA Foundation. While carrying out our sustainability activities in line with long-term goals, we prepared a series of videos under the title of “My Beautiful Country, Nature is In Our Care.” These videos covered issues such as protecting natural resources, water preservation, energy efficiency, waste management, mitigating the carbon footprint, and preventing food waste. We began sharing the videos on our social media accounts during the last quarter of 2020, with the goal to raise awareness on sustainability, as well as highlighting what we can accomplish individually and what we have accomplished as a company.
                    </p>
                    <p>
                        We crowned our success with national and international awards
                        According to the results of the Good Life Brands Survey 2020, conducted with the collaboration of Nielsen and Sustainable Brands Istanbul, we were granted the Good Life Brand title for the third time in a row.
                    </p>
                    <p>
                        At the Edie Sustainability Leaders Awards, one of the most prestigious business awards, we were proud to be the only Turkish company shortlisted in four categories: “Water Management,” “Waste and Resource Management,” “Team of the Year,” and “Employee Participation and Cultural Transformation Leadership.”
                    </p>
                    <p>
                        We were also granted two Golden Awards in the BOC International Brilliance Awards in the categories of “Brilliance in Employee Engagement” and “Brilliance in Use of Technology in IC.”
                    </p>
                    <p>
                        At the Global 100 Awards organized by Platin Magazine in 2020 under the digitalization theme, we were pleased to be recognized as one of the top 10 companies standing out with investments in digitalization, and the number one company in the food industry.
                    </p>
                    <p>
                        In 2020, we, at Ülker Bisküvi, will maintain our growth performance. With a particular focus on our employees, consumers and stakeholders, we will pick up the pace in digital transformation and support the development goals of our country with new investments and strong innovation. We will continue to gain strength in the global marketplace, maintain our strong position in the existing markets, and sustain our leadership.
                    </p>
                    <p>
                        I would like to thank all the stakeholders who support us on our path as we continue production, recruitment, exportation activities, as well as investments for our country.
                    </p>
                    <p>
                        Respectfully yours,
                    </p>
                    <p>
                        <strong>Mete Buyurgan</strong><br />
                        CEO

                    </p>

                </center>
            </div>

        </div>
        <router-link :to="'/'+Lang.SelectedLanguage" class="backhome"> {{Lang.get('backhome')}} </router-link>

        <a v-if="Lang.SelectedLanguage=='tr'" target="_blank" href="/files/CEO-nun Mesajı.pdf" class="download">{{Lang.get('download')}}</a>
        <a v-if="Lang.SelectedLanguage=='en'" target="_blank" href="/files/Message from the CEO.pdf" class="download">{{Lang.get('download')}}</a>
    </div>




</template>

<script>
    import lng from "../store/languages";

    export default {
        name: 'Chairman',
        components: {},
        data() {
            return {
                Lang: lng,
            }
        }
    }</script>
